import styles from './App.module.scss';
import PlotTwistLogo from './plottwistlogo.png'

function App() {
  return (
    <div className={styles.main}>
      <img alt="log" src={PlotTwistLogo}></img>
      <h2>Coming soon</h2>
      <p>Contact: info@plottwiststudios.co</p>
      <p>+34 603 449 962</p>
    </div>
  );
}

export default App;
